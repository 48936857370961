import React from "react";
import { Link } from "gatsby";
import Typist from 'react-typist';
import FadeIn from 'react-fade-in';

import SEO from "../components/seo"

import "../styles/index.css";

const IndexPage = () => {
  const [typingIndex, setTypingIndex] = React.useState(0);
  return (
    <div className='flame'>
      <div className='header'>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Typist avgTypingDelay={85} cursor={{ show: false }}>
          <span className="title">kourin.jp</span>
        </Typist>
      </div>

      <div className="content">
        <FadeIn delay={1250}>
          <span className="coming-soon">COMING SOON</span>
        </FadeIn>
      </div>
      <div className="footer">
      </div>
    </div>
  )
}

export default IndexPage
